// import { Injectable } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/compat/database';

// @Injectable({
//   providedIn: 'root',
// })
// export class ChatService {
//   constructor(private db: AngularFireDatabase) {}

//   getConversations(userId: string, role: string) {
//     if (role === 'admin') {
//       // Admin sees all conversations
//       return this.db.list('conversations').valueChanges();
//     } else {
//       // Client sees only their conversation
//       return this.db
//         .list('conversations', (ref) =>
//           ref.orderByChild('clientId').equalTo(userId)
//         )
//         .valueChanges();
//     }
//   }

//   sendMessage(conversationId: string, sender: string, message: string) {
//     this.db.list(`conversations/${conversationId}/messages`).push({
//       sender,
//       message,
//       timestamp: Date.now(),
//     });
//   }

//   startConversation(clientId: string, clientName: string) {
//     const conversationId = this.db.createPushId();
//     this.db.object(`conversations/${conversationId}`).set({
//       clientId,
//       clientName,
//       messages: [],
//     });
//     return conversationId;
//   }
// }

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(
    private firestore: AngularFirestore,
    private db: AngularFireDatabase
  ) {}

  // Get messages for a specific conversation
  getMessages(conversationId: string): Observable<any[]> {
    // return this.firestore
    //   .collection('conversations')
    //   .doc(conversationId)
    //   .collection('messages', (ref) => ref.orderBy('timestamp'))
    //   .valueChanges();
    return this.db
      .list(`conversations/${conversationId}/messages`)
      .valueChanges();
  }

  // Send a message to a conversation
  sendMessage(conversationId: string, message: string, sender: string) {
    const messageData = {
      message,
      sender,
      timestamp: new Date(),
    };

    // return this.firestore
    //   .collection('conversations')
    //   .doc(conversationId)
    //   .collection('messages')
    //   .add(messageData);

    return this.db.list(`conversations/${conversationId}/messages`).push({
      sender,
      message,
      timestamp: Date.now(),
    });
  }
}
