import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Client Dashboard | Builtdesign';
  isOnline: boolean;
  constructor(private router: Router, private gaService: GoogleAnalyticsService) {
    this.isOnline = false;
  }
  ngOnInit(): void {
    this.trackPage();
    this.updateOnlineStatus();

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
  }
  trackPage() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.gaService.trackPageView(event.urlAfterRedirects);
      }
    });
  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    console.info(`isOnline=[${this.isOnline}]`);
  }
}
