import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, of } from 'rxjs';
import { calculateTime } from 'src/app/utils/timeAgo';

import { MetadataTypeEnum } from 'src/app/utils/config/enum';
import { Keys } from 'src/app/utils/config/keys';
import { ApiService } from 'src/app/services/api.service';
import { ApiConfig } from 'src/app/utils/config/apiConfig';
import { CognitoService } from 'src/app/services/cognito.service';
import { JobService } from 'src/app/services/job.service';
import { TokenService } from 'src/app/services/token.service';
import { HttpErrorResponse } from '@angular/common/http';

interface INotification {
  _id: string;
  title: string;
  description: string;
  is_read: boolean;
}
@Component({
  selector: 'app-explore-dashboard',
  styleUrls: ['./explore-dashboard.component.scss'],
  templateUrl: './explore-dashboard.component.html',
})
export class ExploreLayoutComponent {
  isModalOpen: boolean = false;
  isFeedbackModalOpen: boolean = false;
  contactForm: FormGroup;
  feedbackForm: FormGroup;
  issueType: string = '';
  isSelected!: boolean;
  isNotificationPanelVisible: boolean = false;
  unreadCount: number = 0;
  notifications: INotification[] = [];
  showViewAll: boolean = false;
  notificationUrl: string;
  hasMoreNotifications: boolean = false;
  loadingMore: boolean = false;
  offset: number = 1;
  isLoggedIn!: boolean;
  isSmallScreen: boolean = false;
  isCollapsed: BooleanInput;
  public metadataTypeEnum = MetadataTypeEnum;
  metaData: any = [];
  constructor(
    private tokenService: TokenService,
    private router: Router,
    private cognitoService: CognitoService,
    private notification: NzNotificationService,
    private fb: FormBuilder,
    private jobService: JobService,
    private apiService: ApiService,
  ) {
    this.contactForm = this.fb.group({
      type: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.feedbackForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      feedback_text: ['', Validators.required],
    });

    this.notificationUrl = ApiConfig.urlConstant.notification;
  }

  ngOnInit(): void {
    this.checkScreenSize();
    const token = this.tokenService.getToken(); // Check if the user is logged in
    token?.length ? (this.isLoggedIn = true) : (this.isLoggedIn = false);
    this.getAllMetaData();
    if (this.isLoggedIn) {
      this.checkScreenSize();
      this.getAllNotification();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth < 768;
  }

  handleSidebar() {
    if (this.isSmallScreen) this.isCollapsed = true;
  }

  // Method to handle user sign out
  signOut() {
    this.cognitoService.handleSignOut().then((res: any) => {
      this.router.navigate(['/login']);
      localStorage.clear();
    });
  }

  makeQuery() {
    const payload = this.contactForm.value;
    this.jobService.postQuery({ ...payload }).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.isModalOpen = false;
          this.notification.success('Success', 'Your query raised successfully!');
          this.contactForm.reset();
        }
      },
    });
  }

  handleModalClose() {
    this.isModalOpen = false;
  }

  handleModalOpen() {
    this.isModalOpen = true;
  }

  postFeedback() {
    const payload = this.feedbackForm.value;
    this.jobService.postFeedback({ ...payload }).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.handleFeedbackModalClose();
          this.notification.success('Success', 'Your Feedback is sent successfully!');
          this.feedbackForm.reset();
        }
      },
    });
  }

  handleFeedbackModalClose() {
    this.isFeedbackModalOpen = false;
    this.isSelected = false;
  }

  openFeedbackModal() {
    this.isFeedbackModalOpen = true;
  }

  onSubmit() {}

  getAllNotification() {
    this.apiService
      .getRequest(this.notificationUrl, {
        params: {
          limit: 5,
          sortOrder: 'DESC',
        },
      })
      .subscribe({
        next: (result) => {
          const { data, success } = result;
          if (data) {
            this.notifications = data?.data;
            if (data?.total > 5) this.showViewAll = true;
            this.hasMoreNotifications = data?.hasNextPage;
          }
        },
      });
  }

  onLoadMoreNotifications() {
    this.loadingMore = true;
    this.offset++;
    this.apiService
      .getRequest(`${this.notificationUrl}`, {
        params: {
          offset: this.offset,
          sortOrder: 'DESC',
          limit: 5,
        },
      })
      .pipe(catchError(() => of({ results: [] })))
      .subscribe((res: any) => {
        const { data, success } = res;
        const notificationsSize = this.notifications.length;
        if (this.notifications[notificationsSize - 1]?._id === data.data[0]?._id) {
          console.log('ok');

          this.notifications.pop();
        }
        this.notifications = this.notifications.concat(data.data);
        this.hasMoreNotifications = res?.data?.hasNextPage;
        this.loadingMore = false;
      });
  }

  calculateTime(date: string) {
    return calculateTime(date);
  }

  markAllAsRead() {
    this.apiService.patchRequest(`${this.notificationUrl}/mark-all-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.notifications.forEach((item) => {
            item.is_read = true;
          });
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  viewAllNotifications() {}

  markAsRead(id: string) {
    this.apiService.patchRequest(`${this.notificationUrl}/${id}/mark-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          const indexOfReaded = this.notifications.findIndex((item) => item?._id === id);
          this.notifications[indexOfReaded].is_read = true;
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  getUnreadNotificationCount() {
    this.apiService.getRequest(`${this.notificationUrl}/unread-count`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data?.unreadCount >= 0) {
          this.unreadCount = data?.unreadCount;
          // console.log(data);
        }
      },
    });
  }

  getAllMetaData() {
    const urlConst = `?limit=${200}`;

    this.apiService.getRequest(ApiConfig.urlConstant.metaData + urlConst).subscribe({
      next: (result: any) => {
        const { success, data } = result;

        if (data) {
          this.metaData = data.data;
        } else {
          this.notification.info('Info', 'No record for meta data!');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.notification.error('Error', err?.message);
      },
      complete: () => {
        let dummyMetaData: any = {
          skills: [],
          workCategories: [],
          projectTypes: [],
          softwareSkills: [],
        };

        this.metaData?.forEach((data: any) => {
          switch (data.type) {
            case this.metadataTypeEnum.SKILLS:
              dummyMetaData.skills.push(data);
              break;
            case this.metadataTypeEnum.WORK_CATEGORY:
              dummyMetaData.workCategories.push(data);
              break;
            case this.metadataTypeEnum.PROJECT_TYPE:
              dummyMetaData.projectTypes.push(data);
              break;
            case this.metadataTypeEnum.SOFTWARE_SKILLS:
              dummyMetaData.softwareSkills.push(data);
              break;
          }
        });
        localStorage.setItem(Keys.META_DATA, JSON.stringify(dummyMetaData));
      },
    });
  }
}
