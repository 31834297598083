import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  // Track a page view
  public trackPageView(url: string): void {
    if (typeof gtag === 'function') {
      gtag('config', environment.gtagId, {
        // Replace with your Measurement ID
        page_path: url,
      });
    }
  }

  // Track a custom event
  public trackEvent(eventName: string, params: any = {}): void {
    if (typeof gtag === 'function') {
      gtag('event', eventName, params);
    }
  }
}
