import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-section',
  templateUrl: './chat-section.component.html',
  styleUrls: ['./chat-section.component.scss'],
  // encapsulation: ViewEncapsulation.Emulated,
})
export class ChatSectionComponent implements OnInit {
  @Input() conversationId: string = ''; // Conversation ID/Ticket ID passed from parent
  @Input() sender: string = ''; // Sender's name (e.g., Admin or Client)
  @Input() senderID: string = ''; // Sender's ID
  @Input() recipientID: string = '';

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport | undefined;

  loading: boolean = false;
  chat: boolean = true;
  inputValue!: string;
  submitting!: boolean;
  fileForm: FormGroup;
  messages: any[] = [];
  newMessage: string = '';
  showFileUpload: boolean = false;
  selectedReply: any = null;

  constructor(private fb: FormBuilder, private chatService: ChatService) {
    this.fileForm = this.fb.group({
      files: [[]],
    });
  }

  ngOnInit() {
    if (this.conversationId) {
      this.chatService.getMessages(this.conversationId).subscribe((messages) => {
        this.messages = messages;

        this.chatService.markAsRead(this.conversationId, this.senderID);
        setTimeout(() => {
          this.viewportScrollToBottom();
        }, 500);
      });
    }
  }

  sendMessage() {
    if (this.newMessage.trim()) {
      const replyTo = this.selectedReply?.messageId || null;
      this.chatService
        .sendMessage(this.conversationId, this.newMessage, this.sender, this.senderID, this.recipientID, replyTo)
        .then(() => {
          this.newMessage = ''; // Clear input field after sending
          this.clearReplySelection();
        });
    }
  }

  sendMessageWithAttachement() {
    if (this.fileForm && this.fileForm.controls['files']?.value && this.fileForm.controls['files'].value.length > 0) {
      const replyTo = this.selectedReply?.messageId || null;
      this.chatService
        .sendMessage(this.conversationId, this.fileForm.value, this.sender, this.senderID, this.recipientID, replyTo)
        .then(() => {
          // Clear input field after sending
          // this.fileForm.setValue({
          //   files: [[]],
          // });
          this.clearReplySelection();
          this.showFileUpload = false;
        });
    }
  }
  // messages: any = [
  //   {
  //     type: 'text', // Text message type
  //     message: 'Hello! How can I assist you today?',
  //     reply: false, // Message sent by support, not the user
  //     user: {
  //       name: 'Admin',
  //     },
  //     date: new Date('2023-09-01T10:30:00'), // Custom date
  //   },
  //   {
  //     type: 'text',
  //     message: 'I am facing issues with my recent order.',
  //     reply: true, // This message is sent by the user
  //     user: {
  //       name: 'Bd-112293',
  //     },
  //     date: new Date('2023-09-01T10:31:00'),
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user: {
  //       name: 'BD-10092',
  //     },
  //     date: new Date('2023-09-01T10:32:00'),
  //     files: [
  //       {
  //         url: 'https://example.com/invoice_123.pdf',
  //         icon: 'file-text-outline',
  //         name: 'invoice_123.pdf',
  //       },
  //     ],
  //   },
  //   {
  //     type: 'quote',
  //     message: 'Can you check if the delivery address is correct?',
  //     reply: true,
  //     user: {
  //       name: 'BD-19292',
  //     },
  //     date: new Date('2023-09-01T10:33:00'),
  //     files: null,
  //     quote: {
  //       message: 'Order #123456 has been shipped.',
  //       sender: 'Support',
  //       date: '2023-08-31T10:00:00',
  //     },
  //   },
  //   {
  //     type: 'map',
  //     message: 'Here is the location of your delivery.',
  //     reply: false,
  //     user: {
  //       name: 'BD-11129',
  //     },
  //     date: new Date('2023-09-01T10:34:00'),
  //     latitude: 37.7749, // Example coordinates (San Francisco)
  //     longitude: -122.4194,
  //   },
  //   {
  //     type: 'text',
  //     message: 'Thanks for the update! The address is correct.',
  //     reply: true,
  //     user: {
  //       name: 'BD-10010',
  //     },
  //     date: new Date('2023-09-01T10:35:00'),
  //   },
  // ];
  // messages: any = [
  //   {
  //     type: 'text', // Text message type
  //     message: 'Hello! How can I assist you today?',
  //     reply: false, // Message sent by support, not the user
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:30:00'), // Custom date
  //   },
  //   {
  //     type: 'text',
  //     message: 'I am facing issues with my recent order.',
  //     reply: true, // This message is sent by the user
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:31:00'),
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:32:00'),
  //     files: [
  //       {
  //         url: 'https://example.com/invoice_123.pdf',
  //         icon: 'file-text-outline',
  //         name: 'invoice_123.pdf',
  //       },
  //     ],
  //   },
  //   {
  //     type: 'quote',
  //     message: 'Can you check if the delivery address is correct?',
  //     reply: true,
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:33:00'),
  //     files: null,
  //     quote: {
  //       message: 'Order #123456 has been shipped.',
  //       sender: 'Support',
  //       date: '2023-08-31T10:00:00',
  //     },
  //   },
  //   {
  //     type: 'map',
  //     message: 'Here is the location of your delivery.',
  //     reply: false,
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:34:00'),
  //     latitude: 37.7749, // Example coordinates (San Francisco)
  //     longitude: -122.4194,
  //   },
  //   {
  //     type: 'text',
  //     message: 'Thanks for the update! The address is correct.',
  //     reply: true,
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:35:00'),
  //   },
  // ];

  // data: any = [
  //   {
  //     type: 'text', // Text message type
  //     message: 'Hello! How can I assist you today?',
  //     reply: false, // Message sent by support, not the
  //     user_type: 'me',
  //     user: {
  //       name: 'Support',
  //     },
  //     date: new Date('2023-09-01T10:30:00'), // Custom date
  //   },
  //   {
  //     type: 'text',
  //     message: 'I am facing issues with my recent order.',
  //     reply: true, // This message is sent by the user
  //     user_type: 'you',
  //     user: {
  //       name: 'User',
  //     },
  //     date: new Date('2023-09-01T10:31:00'),
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user_type: 'me',
  //     user: {
  //       name: 'Support',
  //     },
  //   },
  //   {
  //     type: 'file',
  //     message: "Here's the invoice for your order: invoice_123.pdf",
  //     reply: false,
  //     user_type: 'you',
  //     user: {
  //       name: 'Support',
  //     },
  //   },
  // ];
  // sendMessage(event: any) {
  //   const newMessage = {
  //     message: event.message,
  //     sender: 'User',
  //     date: new Date(),
  //     type: 'text',
  //     reply: true,
  //   };
  //   this.messages.push(newMessage);
  // }

  openChat() {
    this.chat = true;
  }

  handleSubmit() {}

  backBtn() {
    this.chat = false;
  }

  viewportScrollToBottom(): void {
    this.viewport?.scrollTo({
      bottom: 0,
      behavior: 'smooth',
    });
  }

  openFileUpload() {
    this.fileForm.controls['files']?.setValue([]);
    this.showFileUpload = true;
  }

  closeFileUpload() {
    this.fileForm.controls['files']?.setValue([]);
    this.showFileUpload = false;
  }

  isPDF(fileName: string): Boolean {
    const fileExtension = fileName?.split('.').pop()?.toLowerCase();
    if (fileExtension) {
      return fileExtension === 'pdf';
    }
    return false;
  }

  isImage(fileName: string): Boolean {
    const fileExtension = fileName?.split('.').pop()?.toLowerCase();
    if (fileExtension) {
      return ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    }
    return false;
  }
  shortenFilename(filename: string, startChars: number, endChars: number) {
    const extension = filename?.split('.').pop();
    const baseName = filename?.slice(0, -(extension ? extension?.length + 1 : 0));

    if (baseName?.length <= startChars + endChars) {
      return filename; // No need to shorten if the base name is already short
    }

    const start = baseName?.slice(0, startChars);
    const end = baseName?.slice(-endChars);

    return `${start}...${end}.${extension}`;
  }
  // Select a message to reply to
  selectMessageForReply(message: any) {
    this.selectedReply = message;
  }

  // Clear reply selection
  clearReplySelection() {
    this.selectedReply = null;
  }

  // Get original message for displaying replies
  getOriginalMessage(replyToId: string): any {
    const originalMessage = this.messages.find((msg) => msg.messageId === replyToId);
    return originalMessage ? originalMessage.message : 'Message not found';
  }

  // Scroll to the original message when clicking on a reply
  scrollToMessage(replyToId: string) {
    setTimeout(() => {
      const targetElement = document.getElementById(`msg-${replyToId}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        targetElement.classList.add('highlight');
        setTimeout(() => {
          targetElement.classList.remove('highlight');
        }, 1500); // Remove highlight after 1.5 seconds
      }
    }, 100);
  }
}
