export enum JobStatus {
  INITIATED = 1,
  UNDER_REVIEW = 2,
  SUCCESS = 3,
  DECLINED = 4,
  HOLD = 5,
}

export enum MetadataTypeEnum {
  SKILLS = 1,
  WORK_CATEGORY = 2,
  PROJECT_TYPE = 3,
  SOFTWARE_SKILLS = 4,
}
